import {Route, Switch} from "react-router-dom";
import LoginPage from "../Login";
import LogoutPage from "../Logout";
import CollectiblesListPage from "../Collectibles/list";
import CollectibleDetailPage from "../Collectibles/detail";
import CollectibleCreatePage from "../Collectibles/create";
import ListingsPage from "pages/Listing/list";
import PacksListPage from "pages/Packs/list";
import PackCreatePage from "pages/Packs/create";
import UsersListPage from "../Users/List";
import UserDetailPage from "../Users/Detail";
import UserCreatePage from "../Users/Create";
import HomePage from "../Home";
import React from "react";
import {Redirect} from "react-router";

interface RoutesProps {
  isAdmin: boolean
  isAuth: boolean
}
const Routes = ({isAdmin, isAuth}: RoutesProps) => {

  const PrivateRoute = ({authCheck, component, ...rest}: any) => {
    const routeComponent = (props: any) => (
      authCheck
        ? React.createElement(component, props)
        : <Redirect to={{pathname: '/auth/login'}}/>
    );
    return <Route {...rest} render={routeComponent}/>;
  };

  const AuthRoute = ({...props}: any) => <PrivateRoute authCheck={isAuth} {...props} />
  const AdminRoute = ({...props}: any) => <PrivateRoute authCheck={isAdmin} {...props} />

  return <Switch>
    <Route path="/auth/login">
      <LoginPage/>
    </Route>

    <AuthRoute path="/auth/logout" component={LogoutPage} />

    <AuthRoute path="/collectibles" exact component={CollectiblesListPage} />
    <AuthRoute path="/collectibles/new" exact component={CollectibleCreatePage} />
    <AuthRoute path="/collectibles/:id/edit" exact component={CollectibleCreatePage} />
    <AuthRoute path="/collectibles/:id" exact component={CollectibleDetailPage} />

    <AuthRoute path="/packs" exact component={PacksListPage} />
    <AuthRoute path="/packs/new" exact component={PackCreatePage} />

    <AuthRoute path="/listings" exact component={ListingsPage} />

    <AdminRoute path="/admin/users" exact component={UsersListPage} />
    <AdminRoute path="/admin/users/add" exact component={UserCreatePage} />
    <AdminRoute path="/admin/users/:id" exact component={UserDetailPage} />

    <AuthRoute path="/" component={HomePage} />
  </Switch>
}

export default Routes;
