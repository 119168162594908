import React, {useEffect} from "react";
import {Link, useParams} from 'react-router-dom';
import {Badge, Button, Card, Col, Descriptions, Form, Input, InputNumber, Row, Table, Tabs} from 'antd';
import {useAppDispatch, useSelector} from "store";
import {fetchCollectibleAction} from "reducers/collectibles";
import Loading from "components/Loading";
import api from "services/ApiService";
import {setError, setSuccess} from "reducers/message";

const CollectibleDetailPage = () => {
  const [form] = Form.useForm();

  const {id} = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const fetchStatus = useSelector(state => state.status['getCollectibleDetails'])
  const collectible = useSelector(state => state.collectibles.item)

  useEffect(() => {
    dispatch(fetchCollectibleAction(id))
  }, [dispatch]);

  if (fetchStatus !== "succeeded" || !collectible) return <Loading/>;

  const onFinish = (values) => {
    api.post(`/admin/collectibles/${id}/issue`, {count: values.count})
      .then(() => dispatch(setSuccess("Issue process started")))
  }


  const handleList = (values) => {
    const data = {
      count: values.count,
      price: parseInt(values.price.replace(".", ""))
    };

    if (data.count <= 0 || data.price <= 0) {
      dispatch(setError("Please enter valid price and count"))
    } else {
      api.post(`/admin/list/collectible/${id}`, data)
        .then(() => dispatch(fetchCollectibleAction(id)))
        .catch(error => dispatch(setError("Failed to list collectible")))
    }
  }

  const getIssueDetails = () => {
    return <Descriptions bordered column={1}>
      <Descriptions.Item label="Issued at">{collectible.issuedAt}</Descriptions.Item>
      <Descriptions.Item label="Total">{collectible.issuedCount}</Descriptions.Item>
      <Descriptions.Item label="Issued">{collectible.cardCount}</Descriptions.Item>
      <Descriptions.Item label="Packed">{collectible.packedCount}</Descriptions.Item>
      <Descriptions.Item label="Owned">{collectible.cardWithOwnerCount}</Descriptions.Item>
    </Descriptions>;
  }

  const getIssueForm = () => <Form
    form={form}
    layout="inline"
    onFinish={onFinish}>
    <Form.Item label="Count" name="count">
      <InputNumber/>
    </Form.Item>
    <Form.Item>
      <Button type="primary" htmlType="submit">
        Start Issue process
      </Button>
    </Form.Item>
  </Form>;

  const getListForm = () => {
    const listedTotal = collectible.listedSingleCount + collectible.listedPackedCount;
    const listableCount = collectible.issuedCount - listedTotal;
    if (listableCount === 0) return null;

    return <div style={{float: "right"}}>
      <Form layout={"inline"} initialValues={{count: listableCount, price: "0.00"}} onFinish={values => handleList(values)}>
        <Form.Item name="count" label={"Count"}><InputNumber size={"small"} style={{width: 50}} max={listableCount}/></Form.Item>
        <Form.Item name="price" label={"Price"}><InputNumber<string> stringMode step="0.01" size={"small"} style={{width: 80}}/></Form.Item>
        <Form.Item><Button size={"small"} htmlType={"submit"} type={"primary"}>List</Button></Form.Item>
      </Form>
    </div>
  }

  return <div>
    <h1>{collectible.title}</h1>
    <Card title={"File"} style={{marginTop: 20}}>
      <img alt="" src={collectible.originalImageURL} width={200}/>
    </Card>
    <Card title="Issue status" style={{marginTop: 20}}>
      {collectible.issuedAt ? getIssueDetails() : getIssueForm()}
    </Card>
    <Card title="Listing status" style={{marginTop: 20}}>
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Listed single">
          {collectible.listedSingleCount}
          {getListForm()}
        </Descriptions.Item>
        <Descriptions.Item label="Listed in pack">{collectible.listedPackedCount}</Descriptions.Item>
      </Descriptions>
    </Card>
  </div>
};

export default CollectibleDetailPage;
