import React, {useEffect, useState} from "react";
import {Button, Form, Input, Spin, Descriptions, InputNumber} from 'antd';
import {useAppDispatch, useSelector} from "store";
import api from "services/ApiService";
import {setError} from "reducers/message";
import {useHistory} from "react-router-dom";
import {fetchCollectiblesAction} from "reducers/collectibles";

interface CreatePackItem {
  collectibleId: string
  count: number
}

const CollectiblesListPage = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [saving, setSaving] = useState<boolean>(false);
  const [counts, setCounts] = useState<{ string: number }>({} as { string: number });
  const collectibles = useSelector(state => state.collectibles.items);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCollectiblesAction());
  }, [])

  useEffect(() => {
    const newCounts: { string: number } = {} as { string: number };
    collectibles.forEach(col => newCounts[col.id] = 0);
    setCounts(newCounts);
  }, [collectibles])

  const onFinish = async (values) => {
    if (!values.name) dispatch(setError("Please enter name"))
    else {
      const items: CreatePackItem[] = [];
      Object.keys(counts).forEach(cid => {
        if (counts[cid] > 0) items.push({collectibleId: cid, count: counts[cid]})
      });

      if (items.length < 3) dispatch(setError("Please select at least 3 collectibles"))
      else {
        setSaving(true);
        api.post('/admin/packs/definitions', {name: values.name, items})
          .then(() => history.push("/packs"))
          .catch(e => {
            dispatch(setError((e as Error).message));
            setSaving(false);
          })
      }
    }
  }

  const onCountChange = (e, cid) => {
    try {
      const c = parseInt(e);
      setCounts({...counts, [cid]: c});
    } catch (e) {
      console.log(e);
    }
  }
  return <div>
    <Spin spinning={saving}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}>
        <Form.Item label="Name" name="name">
          <Input/>
        </Form.Item>
        <Form.Item label={"Collectibles"}>
          <Descriptions column={1} bordered>
            {
              collectibles.map(col => {
                return <Descriptions.Item label={col.title} key={col.id}>
                  <InputNumber style={{width: 80}} value={counts[col.id]} onChange={e => onCountChange(e, col.id)}/>
                </Descriptions.Item>
              })
            }
          </Descriptions>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  </div>
};

export default CollectiblesListPage;
