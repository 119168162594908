import React, {useEffect} from "react";
import {Link} from 'react-router-dom';
import {Button, Col, Form, Input, Row, Table, Tag} from 'antd';
import {useAppDispatch, useSelector} from "store";
import {fetchCollectiblesAction} from "reducers/collectibles";
import Loading from "components/Loading";
import api from "services/ApiService";
import {setError} from "reducers/message";
import {Collectible} from "reducers/types";
import {EditOutlined, CheckCircleTwoTone, CloseCircleTwoTone} from "@ant-design/icons";

const CollectiblesListPage = () => {
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();
  const listStatus = useSelector(state => state.status['getCollectibles'])
  const collectibles = useSelector(state => state.collectibles.items)

  useEffect(() => {
    dispatch(fetchCollectiblesAction())
  }, [dispatch]);

  if (listStatus !== "succeeded") return <Loading/>;

  const onFinish = (values) => {
    api.post('/admin/collectibles', {title: values.title})
      .then((res) => {
        console.log(res);
      })
      .catch(error => dispatch(setError(error)));
  }

  return <div>
    <Table dataSource={collectibles} rowKey="id" pagination={false}>
      <Table.Column render={(row: Collectible) => <Link to={`/collectibles/${row.id}/edit`}><EditOutlined /></Link>} key={"title"}/>
      <Table.Column title={"Title"} render={(row: Collectible) => <Link to={`/collectibles/${row.id}`}>{row.title}</Link>} key={"title"}/>
      <Table.Column title={"Country"} dataIndex={"countryCode"} key={"countryCode"}/>
      <Table.Column title={"Period"} key={"period"} render={(row: Collectible) => <Tag color="gold">{row.period}</Tag>} />
      <Table.Column title={"Period"} key={"cardType"} render={(row: Collectible) => <Tag color="orange">{row.cardType}</Tag>} />
      <Table.Column title={"Period"} key={"statud"} render={(row: Collectible) => <Tag color="volcano">{row.status}</Tag>} />
      <Table.Column title={"First Issue Date"} render={(row: Collectible) => row.firstIssuedAt} key={"firstIssuedAt"}/>
      <Table.Column title={"Is Issued?"} render={(row: Collectible) => row.issuedAt ? <CheckCircleTwoTone twoToneColor={"#52c41a"} /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />} key={"issuedAt"} align={"center"}/>
      <Table.Column title={"Issue Size"} dataIndex={"issuedCount"} key={"issuedCount"} align={"right"}/>
    </Table>
    <div style={{padding: 20, textAlign: "right"}}><Link to={"/collectibles/new"}><Button type={"primary"}>Add New</Button></Link></div>
  </div>
};

export default CollectiblesListPage;
