import React, {useEffect} from "react";
import {Link} from 'react-router-dom';
import {Badge, Button, Descriptions, Form, InputNumber, Table} from 'antd';
import {useAppDispatch, useSelector} from "store";
import Loading from "components/Loading";
import {PackDefinition} from "reducers/types";
import {fetchPackDefinitionsAction} from "reducers/packs";
import api from "services/ApiService";
import {setError} from "reducers/message";

const PacksListPage = () => {
  const dispatch = useAppDispatch();
  const listStatus = useSelector(state => state.status['getPackDefinitions'])
  const packDefinitions = useSelector(state => state.packs.definitions)

  useEffect(() => {
    dispatch(fetchPackDefinitionsAction())
  }, [dispatch]);

  if (listStatus !== "succeeded") return <Loading/>;

  const handleList = (packDefinitionId: string, values) => {
    api.post(`/admin/list/pack/${packDefinitionId}`, values)
      .then(() => dispatch(fetchPackDefinitionsAction()))
      .catch(error => dispatch(setError("Failed to list packs")))
  }

  const renderCollectibles = (row: PackDefinition) => {
    return <div>
      {
        row.items.map(item => <div style={{float: "left", marginRight: 40}}>
          {item.count} x <img key={item.collectibleId} src={item.collectibleImageURL} width={50}/>
        </div>)
      }
    </div>
  }

  const renderListed = (row: PackDefinition) => {
    if (row.listedCount === row.totalCount) return <span>{row.listedCount}/{row.totalCount}</span>;

    return <Form layout={"inline"} initialValues={{count: (row.totalCount - row.listedCount)}} onFinish={values => handleList(row.id, values)}>
      <Form.Item name="count" label={"Count"}><InputNumber size={"small"} style={{width: 50}}/></Form.Item>
      <Form.Item name="price" label={"Price"}><InputNumber size={"small"} style={{width: 80}}/></Form.Item>
      <Form.Item><Button size={"small"} htmlType={"submit"} type={"primary"}>List</Button></Form.Item>
    </Form>
  }

  return <div>
    <Table dataSource={packDefinitions} rowKey="id" pagination={false} bordered size={"small"}>
      <Table.Column title={"Name"} dataIndex={"name"} key={"name"}/>
      <Table.Column title={"Collectibles"} render={renderCollectibles} key={"collectibles"}/>
      <Table.Column title={"Total"} dataIndex={"totalCount"} key={"totalCount"} align={"center"}/>
      <Table.Column title={"Listed"} render={renderListed}/>
    </Table>
    <div style={{padding: 20, textAlign: "right"}}><Link to={"/packs/new"}><Button type={"default"}>Add New</Button></Link></div>
  </div>
};

export default PacksListPage;
