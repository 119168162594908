import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch} from "store";
import api from "services/ApiService";
import {Collectible} from "./types";
import {statusError, statusInit, statusSuccess} from "reducers/status";

export interface CollectiblesState {
  error: string | null
  items: Array<Collectible>
  item?: Collectible
}

const initialState: CollectiblesState = {
  error: null,
  items: [],
}

const reducer = createSlice({
  name: 'collectibles',
  initialState,
  reducers: {
    listSuccess(state, action: PayloadAction<Collectible[]>) {
      state.items = action.payload
    },
    getItemSuccess(state, action: PayloadAction<Collectible>) {
      state.item = action.payload
    },
  }
});

const {
  listSuccess,
  getItemSuccess,
} = reducer.actions;

export const fetchCollectiblesAction = () => (dispatch: AppDispatch) => {
  dispatch(statusInit("getCollectibles"));
  api.get('/admin/collectibles')
    .then(res => {
      dispatch(statusSuccess("getCollectibles"));
      dispatch(listSuccess(res));
    })
    .catch(() => dispatch(statusError("getCollectibles")))
}

export const fetchCollectibleAction = (id: string) => (dispatch: AppDispatch) => {
  dispatch(statusInit("getCollectibleDetails"));
  api.get(`/admin/collectibles/${id}`)
    .then(res => {
      dispatch(statusSuccess("getCollectibleDetails"));
      dispatch(getItemSuccess(res));
    })
    .catch(err => {
      dispatch(statusError("getCollectibleDetails"))
    })
}


export default reducer.reducer;
