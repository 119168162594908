import React, {useEffect} from "react";
import { useParams } from 'react-router-dom';
import {Descriptions} from "antd";
import {useAppDispatch, useSelector} from "store";
import {getUserAction} from "reducers/users";
import Loading from "components/Loading";

interface Query {
  id?: string
}

const Index = () => {

  const {id} = useParams<Query>();
  const user = useSelector(state => state.users.user)
  const dispatch = useAppDispatch();

  useEffect(() => {
    const userId = id ? parseInt(id) : 0;
    dispatch(getUserAction(userId));
  }, [id, dispatch])

  if (!user) return <Loading/>;

  return <Descriptions column={1} bordered>
    <Descriptions.Item label="identifier">{user.identifier}</Descriptions.Item>
  </Descriptions>
};

export default Index;
