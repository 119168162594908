import session from 'services/SessionService';

const apiHost = process.env.NODE_ENV === 'production' ? 'https://api.nft.vole.io' : 'http://localhost:9000';

const getHeaders = () => {
  const headers = new Headers()
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  const token = session.getToken();
  if (token !== undefined) {
    headers.append('X-Auth-Token', token);
  }

  return headers;
};

const processRawResponse = (rawResponse: Response) => {
  if (rawResponse.status === 401) {
    throw new Error('AUTH_REQUIRED')
  }

  if (rawResponse.status === 403) {
    throw new Error('AUTH_REQUIRED')
  }

  const parseResponseJson = () => {
    return rawResponse
      .text()
      .then(data => {
        try {
          if (!data || data.length === 0) {
            return {};
          }
          return JSON.parse(data);
        } catch (e) {
          console.error("Cannot parse API response: ", e)
          return {};
        }
      });
  }

  if (rawResponse.status >= 400) {
    return parseResponseJson()
      .then(json => {
        throw new Error(json.error)
      })
  }

  return parseResponseJson();
};

export interface QSParams {
  [x: string]: string | number | undefined
}

const apiGet = (url: string, params?: QSParams) => {
  const paramsArr: Array<string> = [];
  if (params) {
    Object.keys(params).forEach(key => {
      const value = params[key];
      if (value !== undefined && value !== "") {
        paramsArr.push(`${key}=${encodeURIComponent(value)}`)
      }
    })
  }
  const qs = paramsArr.length > 0 ? '?' + paramsArr.join('&') : '';

  return fetch(apiHost + url + qs, {
    method: 'GET',
    headers: getHeaders()
  }).then(processRawResponse);
}

const apiDelete = (url: string) => fetch(apiHost + url, {
  method: 'DELETE',
  headers: getHeaders()
}).then(processRawResponse);

const apiPost = (url: string, data?: {}) => fetch(apiHost + url, {
  method: 'POST',
  headers: getHeaders(),
  body: data === undefined ? null : JSON.stringify(data)
}).then(processRawResponse);

const apiPostFile = (url: string, file: File) => {
  const formData = new FormData();

  formData.append('type', file.type);
  formData.append('file', file);

  const headers = new Headers();
  headers.append('X-Auth-Token', session.getToken() ?? '');

  return fetch(apiHost + url, {
    method: 'POST',
    headers: headers,
    body: formData
  }).then(processRawResponse);
}

export default {
  get: apiGet,
  post: apiPost,
  delete: apiDelete,
  postFile: apiPostFile,
}
