import React, {useEffect} from 'react';
import {useAppDispatch, useSelector} from "store";
import {message} from "antd";
import {clearError} from "reducers/message";

export const Notification = () => {

  const error: string = useSelector(state => state.message.error)
  const success: string = useSelector(state => state.message.success)
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (error) {
      message.error(error)
    }
    if (success) {
      message.success(success)
    }
    dispatch(clearError())
  }, [error, success])

  return null;
}

export default Notification;
