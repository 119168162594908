import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import './style.less';
import {useAppDispatch, useSelector} from "store";
import {AuthState, checkAuthAction} from "reducers/auth";
import {Layout} from "antd";
import Routes from "./Routes";
import Loading from "../../components/Loading";
import Header from './Header';
import Notification from "./Notification";

const {Footer, Content} = Layout;

export const App = () => {

  const auth: AuthState = useSelector(state => state.auth)
  const dispatch = useAppDispatch();

  if (auth.check === "notstarted") {
    dispatch(checkAuthAction())
  }

  if (auth.check !== "done") return <Loading />;

  return (
    <Router>
      <Layout>
        {
          auth.isAuth &&
          <Header />
        }
        <Content className="layout-content">
          <Routes isAuth={auth.isAuth} isAdmin={auth.role === "ADMIN"}/>
        </Content>
        <Footer style={{ textAlign: 'center' }}>©2021</Footer>
        <Notification />
      </Layout>
    </Router>
  );
}

export default App;
