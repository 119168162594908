import React, {useEffect, useState} from "react";
import {Button, Form, Select, Input} from 'antd';
import {useHistory} from 'react-router-dom';
import {useAppDispatch, useSelector} from "store";
import {createUserAction, CreateUserReq} from "reducers/users";

interface FormValues {
  role: string
  identifierType: string
  identifierValue: string,
  password: string
}

const Index = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [initialLoad, setInitialLoad] = useState(true);
  const createUserStatus = useSelector(state => state.status['createUser']);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setInitialLoad(false);
  }, [dispatch]);

  const createUserSucceeded = createUserStatus === "succeeded";

  useEffect(() => {
    if (!initialLoad && createUserSucceeded) {
      history.push('/admin/users');
    }
  }, [initialLoad, createUserSucceeded, history])

  const onFinish = (values: FormValues) => {
    const data: CreateUserReq = {
      identifierType: values.identifierType,
      identifierValue: values.identifierValue,
      role: values.role,
      password: values.password
    }
    dispatch(createUserAction(data))
  }

  const initialValues = {
    role: "SELLER",
    identifierType: "email"
  }

  const formLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 10 },
  };

  const buttonLayout = {
    wrapperCol: { span: 16 }
  }

  return <Form {...formLayout} form={form} layout="horizontal" onFinish={onFinish} initialValues={initialValues}>
    <h3 style={{marginBottom: 20}}>Account Information:</h3>
    <Form.Item label="Identifier">
      <Form.Item noStyle name="identifierType">
        <Select style={{width: '30%'}}>
          <Select.Option value="email">Email</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item noStyle name="identifierValue" rules={[{required: true, message: "Identifier missing"}]}>
        <Input style={{width: '70%'}}/>
      </Form.Item>
    </Form.Item>
    <Form.Item name="password" label={"Password"} rules={[{required: true, message: "Password missing"}]}>
      <Input type={"password"} />
    </Form.Item>
    <Form.Item {...buttonLayout} style={{textAlign: 'right'}}>
      <Button type="primary" htmlType="submit">{"Save"}</Button>
    </Form.Item>
  </Form>
}

export default Index;
