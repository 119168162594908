import {Action, AnyAction, createSlice, PayloadAction} from "@reduxjs/toolkit";

interface RejectedAction extends Action {
  payload: string
}
export interface MessageState {
  error: string
  success: string
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.endsWith('Error')
}

const reducer = createSlice({
  name: 'message',
  initialState: {
    error: '' as string,
    success: '' as string
  },
  reducers: {
    clearError(state) {
      state.error = '';
      state.success = '';
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    setSuccess(state, action: PayloadAction<string>) {
      state.success = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isRejectedAction, (state, action) => {
        state.error = action.payload;
        return state;
      })
  }
});

export const { clearError, setError, setSuccess } = reducer.actions;

export default reducer.reducer;
