import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch} from "store";
import api from "services/ApiService";
import {User} from "./types";
import {statusError, statusInit, statusSuccess} from "reducers/status";

export interface UsersState {
  error: string | null
  users: {
    [id: number]: User
  }
  user?: User
}

export interface CreateUserReq {
  identifierType: string
  identifierValue: string
  role: string
  password: string
}
const initialState: UsersState = {
  error: null,
  users: {}
}

const reducer = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getUsersSuccess(state, action: PayloadAction<Array<User>>) {
      state.users = action.payload.reduce((acc, user) => ({...acc, [user.id]: user}), {})
    },
    getUserSuccess(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    addPlatformError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  }
});

const {
  getUsersSuccess,
  getUserSuccess,
  addPlatformError,
} = reducer.actions;

export const createUserAction = (data: CreateUserReq) => (dispatch: AppDispatch) => {
  dispatch(statusInit("createUser"));
  api.post('/admin/users', data)
    .then(() => dispatch(statusSuccess("createUser")))
    .catch(() => dispatch(statusError("createUser")))
}

export const getUserAction = (id: number) => (dispatch: AppDispatch) => {
  dispatch(statusInit("getUser"));
  api.get(`/admin/users/${id}`)
    .then(res => {
      dispatch(statusSuccess("getUser"));
      dispatch(getUserSuccess(res));
    })
    .catch(() => dispatch(statusError("getUser")))
}

export default reducer.reducer;
