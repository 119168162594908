import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch} from "store";
import api from "services/ApiService";
import {Country, IdName, PackDefinition} from "./types";
import {statusError, statusInit, statusSuccess} from "reducers/status";

export interface LookupState {
  countries: Array<Country>
  collectibleCardTypes: Array<IdName>
  collectiblePeriods: Array<IdName>
  collectibleStatuses: Array<IdName>
}

const initialState: LookupState = {
  countries: [],
  collectibleCardTypes: [],
  collectiblePeriods: [],
  collectibleStatuses: [],
}

const reducer = createSlice({
  name: 'lookup',
  initialState,
  reducers: {
    getCountriesSuccess(state, action: PayloadAction<Country[]>) {
      state.countries = action.payload
    },
    getCardTypesSuccess(state, action: PayloadAction<IdName[]>) {
      state.collectibleCardTypes = action.payload
    },
    getCollectiblePeriodsSuccess(state, action: PayloadAction<IdName[]>) {
      state.collectiblePeriods = action.payload
    },
    getCollectibleStatusesSuccess(state, action: PayloadAction<IdName[]>) {
      state.collectibleStatuses = action.payload
    },
  }
});

const {
  getCountriesSuccess,
  getCardTypesSuccess,
  getCollectiblePeriodsSuccess,
  getCollectibleStatusesSuccess,
} = reducer.actions;

export const getCountriesAction = () => (dispatch: AppDispatch) => {
  dispatch(statusInit("getCountries"));
  api.get('/admin/lookup/countries')
    .then(res => {
      dispatch(statusSuccess("getCountries"));
      dispatch(getCountriesSuccess(res));
    })
    .catch(() => dispatch(statusError("getCountries")))
}

export const getCardTypesAction = () => (dispatch: AppDispatch) => {
  dispatch(statusInit("getCardTypes"));
  api.get('/admin/lookup/collectible/cardTypes')
    .then(res => {
      dispatch(statusSuccess("getCardTypes"));
      dispatch(getCardTypesSuccess(res));
    })
    .catch(() => dispatch(statusError("getCardTypes")))
}

export const getCollectiblePeriodsAction = () => (dispatch: AppDispatch) => {
  dispatch(statusInit("getCollectiblePeriods"));
  api.get('/admin/lookup/collectible/periods')
    .then(res => {
      dispatch(statusSuccess("getCollectiblePeriods"));
      dispatch(getCollectiblePeriodsSuccess(res));
    })
    .catch(() => dispatch(statusError("getCollectiblePeriods")))
}

export const getCollectibleStatusesAction = () => (dispatch: AppDispatch) => {
  dispatch(statusInit("getCollectibleStatusesSuccess"));
  api.get('/admin/lookup/collectible/statuses')
    .then(res => {
      dispatch(statusSuccess("getCollectibleStatusesSuccess"));
      dispatch(getCollectibleStatusesSuccess(res));
    })
    .catch(() => dispatch(statusError("getCollectibleStatusesSuccess")))
}

export default reducer.reducer;
