import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch} from "store";
import api from "services/ApiService";
import {PackDefinition} from "./types";
import {statusError, statusInit, statusSuccess} from "reducers/status";

export interface PacksState {
  definitions: Array<PackDefinition>
}

const initialState: PacksState = {
  definitions: [],
}

const reducer = createSlice({
  name: 'packs',
  initialState,
  reducers: {
    getDefinitionsSuccess(state, action: PayloadAction<PackDefinition[]>) {
      state.definitions = action.payload
    },
  }
});

const {
  getDefinitionsSuccess
} = reducer.actions;

export const fetchPackDefinitionsAction = () => (dispatch: AppDispatch) => {
  dispatch(statusInit("getPackDefinitions"));
  api.get('/admin/packs/definitions')
    .then(res => {
      dispatch(statusSuccess("getPackDefinitions"));
      dispatch(getDefinitionsSuccess(res));
    })
    .catch(() => dispatch(statusError("getPackDefinitions")))
}

export default reducer.reducer;
