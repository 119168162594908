import { combineReducers } from 'redux'
import message from './message';
import auth from './auth';
import collectibles from './collectibles';
import packs from './packs';
import listings from './listings';
import users from './users';
import status from './status';
import lookup from './lookup';

export default combineReducers({
  message,
  listings,
  auth,
  collectibles,
  packs,
  users,
  status,
  lookup,
});
