import {Menu} from "antd";
import {Link} from "react-router-dom";
import {PoweroffOutlined} from "@ant-design/icons";
import React from "react";
import {Layout} from "antd";
import {AuthState} from "reducers/auth";
import {useSelector} from "store";

const { Header } = Layout;

const AppHeader = () => {

  const auth: AuthState = useSelector(state => state.auth)

  const adminMenu = [
    {name: "Collectibles", url: "/collectibles" },
    {name: "Packs", url: "/packs" },
    {name: "Listings", url: "/listings" },
    {name: "Users", url: "/users" },
  ];

  const renderMenuItem = (m: any) => <Menu.Item key={m.name}><Link to={m.url}>{m.name}</Link></Menu.Item>

  return <Header className={`layout-header-role-` + auth.role.toLowerCase()}>
    <div className="layout-logo">NFT ADMIN</div>
    <Menu theme="dark" mode="horizontal">
      {adminMenu.map(m => renderMenuItem(m))}
      <Menu.Item className="layout-menu-logout" icon={<PoweroffOutlined />} key="logout" style={{position: "absolute", right: 0}}>
        <Link to="/auth/logout">Logout</Link>
      </Menu.Item>
    </Menu>
  </Header>
}

export default AppHeader;
