import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {ProgressState} from "reducers/types";

export interface StatusState {
  loadProjects: ProgressState,
  loadFields: ProgressState,
  countIssues: ProgressState,
}

const initialState: StatusState = {
  loadProjects: "idle",
  loadFields: "idle",
  countIssues: "idle",
}

const chartSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    statusInit(state, action: PayloadAction<string>) {
      state[action.payload] = "loading";
    },
    statusReset(state, action: PayloadAction<string>) {
      state[action.payload] = "idle";
    },
    statusSuccess(state, action: PayloadAction<string>) {
      state[action.payload] = "succeeded";
    },
    statusError(state, action: PayloadAction<string>) {
      state[action.payload] = "failed";
    },
  }
})

export const {
  statusInit,
  statusSuccess,
  statusError,
} = chartSlice.actions

export default chartSlice.reducer
