import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Badge, Button, Col, Form, Input, Row, Select, Table} from 'antd';
import {useAppDispatch, useSelector} from "store";
import Loading from "components/Loading";
import {Listing} from "reducers/types";
import {fetchListingsAction, SearchListingsParams} from "reducers/listings";
import {fetchCollectiblesAction} from "reducers/collectibles";

const CollectiblesListPage = () => {
  const [form] = Form.useForm();
  const perPage = 10;

  const dispatch = useAppDispatch();
  const listStatus = useSelector(state => state.status['getListings'])
  const collectibles = useSelector(state => state.collectibles.items)
  const listings = useSelector(state => state.listings.items)
  const total = useSelector(state => state.listings.total)
  const offset = useSelector(state => state.listings.offset)
  const [listParams, setListParams] = useState<SearchListingsParams>({limit: perPage})

  useEffect(() => {
    dispatch(fetchCollectiblesAction())
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchListingsAction(listParams))
  }, [dispatch, listParams]);

  if (listStatus !== "succeeded") return <Loading/>;

  const paginationConfig = {
    disabled: total <= perPage,
    showTotal: (total: number, range: any) => `Total: ${total}`,
    total: total,
    pageSize: perPage,
    current: offset / perPage + 1,
    onChange: (page: number) => {
      setListParams({...listParams, from: (page - 1) * perPage})
    }
  };

  const onFinish = (values) => {
    setListParams({
      ...listParams,
      collectibleId: values.collectibleId,
      serialMin: values.serialMin && parseInt(values.serialMin),
    })
  }

  const renderCard = (listing: Listing) => {
    return <Badge style={{ backgroundColor: '#666' }} overflowCount={999999} count={listing.serialNo}><img alt="" width={50} src={listing.originalImageURL} /></Badge>
  }

  const renderPack = (listing: Listing) => {

    const stringToColour = function(str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      let colour = '#';
      for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
      }
      return colour;
    }

    if (!listing.packId) return '-';
    return <div style={{fontSize: 10, width: 100, textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
      {/*<img alt="" width={30} src={"https://vole-nft-files.s3.eu-central-1.amazonaws.com/packs/default.jpg"} />*/}
      <Link to={"#"} style={{color: stringToColour(listing.packId)}}>{listing.packId}</Link>
    </div>
  }

  const renderPrice = (price) => {
    if (!price) return 0;
    return <span style={{fontWeight: "bold", fontSize: 14}}>{price / 100}</span>
  }

  return <div>
    <Row style={{marginBottom: 20}}>
      <Col>
        <Form form={form} layout="inline" onFinish={onFinish}>
          <Form.Item name="collectibleId" label={"Collectible"}>
            <Select style={{width: 150}}>{collectibles.map(c => <Select.Option value={c.id} key={c.id}>{c.title}</Select.Option>)}</Select>
          </Form.Item>
          <Form.Item name="serialMin" label={"Serial"}>
            <Input />
          </Form.Item>
          <Form.Item>
            <Button htmlType={"submit"}>Search</Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
    <Table dataSource={listings} rowKey="cardId" pagination={paginationConfig}>
      <Table.Column title={"#"} dataIndex={"cardId"} key={"cardId"}/>
      <Table.Column title={"Card"} render={renderCard} key={"title"}/>
      <Table.Column title={"Pack"} render={renderPack} key={"title"}/>
      <Table.Column title={"Price"} dataIndex={"price"} key={"price"} render={renderPrice}/>
    </Table>
  </div>
};

export default CollectiblesListPage;
