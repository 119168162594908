import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch} from "store";
import api from "services/ApiService";
import {Listing, QSParams} from "./types";
import {statusError, statusInit, statusSuccess} from "reducers/status";

export interface ListingsState {
  error: string | null
  items: Array<Listing>
  total: number
  offset: number
  limit: number
}

const initialState: ListingsState = {
  error: null,
  items: [],
  total: 0,
  offset: 0,
  limit: 10,
}


interface ListingSearchResult {
  items: Array<Listing>
  total: number
  offset: number
  limit: number
}


export interface SearchListingsParams extends QSParams {
  collectibleId?: string
  serialMin?: number
  serialMax?: number
  limit?: number
  total?: number
  offset?: number
}

const reducer = createSlice({
  name: 'listings',
  initialState,
  reducers: {
    listSuccess(state, action: PayloadAction<ListingSearchResult>) {
      state.items = action.payload.items
      state.total = action.payload.total
      state.limit = action.payload.limit
      state.offset = action.payload.offset
    },
  }
});

const {
  listSuccess,
} = reducer.actions;

export const fetchListingsAction = (params?: SearchListingsParams) => (dispatch: AppDispatch) => {
  dispatch(statusInit("getListings"));
  api.get('/admin/listings/search', params)
    .then(res => {
      dispatch(statusSuccess("getListings"));
      dispatch(listSuccess(res));
    })
    .catch(() => dispatch(statusError("getListings")))
}


export default reducer.reducer;
