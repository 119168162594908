import React, {useEffect, useRef, useState} from "react";
import {Button, Form, Input, Upload, Spin, Select, DatePicker, InputNumber} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import {useAppDispatch, useSelector} from "store";
import api from "services/ApiService";
import {setError} from "reducers/message";
import {useHistory, useParams} from "react-router-dom";
import {getCardTypesAction, getCollectiblePeriodsAction, getCollectibleStatusesAction, getCountriesAction} from "reducers/lookup";
import Loading from "components/Loading";
import moment from "moment";
import styled from "styled-components";
import {Collectible} from "reducers/types";

const TwoEnds = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center
`

const CollectiblesListPage = () => {
  const [form] = Form.useForm();
  const {id} = useParams<{ id: string }>();
  const history = useHistory();
  const [saving, setSaving] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const countries = useSelector(state => state.lookup.countries);
  const cardTypes = useSelector(state => state.lookup.collectibleCardTypes);
  const periods = useSelector(state => state.lookup.collectiblePeriods);
  const statuses = useSelector(state => state.lookup.collectibleStatuses);

  const [fetched, setFetched] = useState<boolean>(!id);
  const [collectible, setCollectible] = useState<Collectible>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCountriesAction());
    dispatch(getCollectiblePeriodsAction());
    dispatch(getCollectibleStatusesAction());
    dispatch(getCardTypesAction());
    if (id) {
      api.get(`/admin/collectibles/${id}`).then(res => {
        setCollectible(res);
        setFetched(true);
      })
    }
  }, [id]);

  if (!fetched) return <Loading/>;

  const onFinish = async (values) => {
    if (!collectible && (!inputRef.current || !inputRef.current.files || inputRef.current.files.length <= 0)) {
      dispatch(setError("Please select file"))
    } else {
      setSaving(true);
      try {
        const url = collectible ? `/admin/collectibles/${collectible.id}` : '/admin/collectibles';

        const firstIssuedAt = values.firstIssuedAt ? values.firstIssuedAt.format("YYYY-MM-DD") : undefined;
        const data = {
          ...values,
          firstIssuedAt
        }
        const res = await api.post(url, data);
        if (!collectible) {
          const fileRes = await uploadFile(res.id);
        }
        history.push("/collectibles")
      } catch (e) {
        dispatch(setError((e as Error).message));
        setSaving(false);
      }
    }
  }

  const uploadFile = async (id) => {
    if (inputRef.current && inputRef.current.files && inputRef.current.files.length > 0) {
      return await api.postFile(`/admin/collectibles/${id}/file`, inputRef.current.files.item(0)!)
    }
    return false;
  }

  const previewImage = (files: FileList) => {
    const file = files.item(0);
    try {
      setImageUrl(URL.createObjectURL(file));
    } catch (e) {
      dispatch(setError(`Cannot preview image. ${e}`))
    }
  }

  const handleDelete = () => {

  }

  const getPhoto = () => {
    if (collectible) {
      return <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", marginBottom: 20}}>
        <img alt="" src={collectible.originalImageURL} width={200}/>
      </div>
    }
    return <Form.Item wrapperCol={{offset: 8, span: 16}}>
      <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center"}}>
        {
          imageUrl && <img alt="" src={imageUrl} width={200}/>
        }
        <input type="file" multiple={true} ref={inputRef} style={{display: 'none'}} onChange={e => previewImage(e.target.files!)}/>
        <Button style={{marginTop: 20}} onClick={() => inputRef.current !== null && inputRef.current.click()} icon={<UploadOutlined/>}>Select file to
          upload</Button>
      </div>
    </Form.Item>
  }

  const getInitialValues = () => {
    if (collectible) {
      console.log(collectible);
      return {
        title: collectible.title,
        description: collectible.description,
        cardType: collectible.cardType,
        countryCode: collectible.countryCode,
        period: collectible.period,
        status: collectible.status,
        issuedCount: collectible.issuedCount,
        firstIssuedAt: collectible.firstIssuedAt ? moment(collectible.firstIssuedAt) : undefined
      }
    }

    return {};
  }

  const getButtonLabel = () => collectible ? "Update" : "Create";

  return <div style={{maxWidth: 500}}>
    <Spin spinning={saving}>
      <Form
        form={form}
        labelCol={{span: 8}}
        wrapperCol={{span: 16}}
        layout="horizontal"
        initialValues={getInitialValues()}
        onFinish={onFinish}>
        {getPhoto()}
        <Form.Item label="Title" name="title" rules={[{required: true, message: 'Please enter title'}]}>
          <Input/>
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea/>
        </Form.Item>
        <Form.Item label="Country" name="countryCode" rules={[{required: true, message: 'Please select country'}]}>
          <Select>{countries.map(c => <Select.Option value={c.code} key={c.code}>{c.name}</Select.Option>)}</Select>
        </Form.Item>
        <Form.Item label="Period" name="period" rules={[{required: true, message: 'Please select period'}]}>
          <Select>{periods.map(c => <Select.Option value={c.id} key={c.id}>{c.name}</Select.Option>)}</Select>
        </Form.Item>
        <Form.Item label="Card Type" name="cardType" rules={[{required: true, message: 'Please select card type'}]}>
          <Select>{cardTypes.map(c => <Select.Option value={c.id} key={c.id}>{c.name}</Select.Option>)}</Select>
        </Form.Item>
        <Form.Item label="Status" name="status" rules={[{required: true, message: 'Please select status'}]}>
          <Select>{statuses.map(c => <Select.Option value={c.id} key={c.id}>{c.name}</Select.Option>)}</Select>
        </Form.Item>
        <Form.Item label="First Issue Date" name="firstIssuedAt">
          <DatePicker/>
        </Form.Item>
        <Form.Item label="Issue Size" name="issuedCount">
          <InputNumber/>
        </Form.Item>
        <Form.Item wrapperCol={{offset: 8, span: 16}}>
          <TwoEnds>
            <Button type="dashed" onClick={handleDelete}>Delete</Button>
            <Button type="primary" htmlType="submit">{getButtonLabel()}</Button>
          </TwoEnds>
        </Form.Item>
      </Form>
    </Spin>
  </div>
};

export default CollectiblesListPage;
