import React, {useEffect} from "react";
import {Row, Col, Table, Tag, Button} from 'antd';
import {useAppDispatch, useSelector} from "store";
import {Link} from "react-router-dom";
import Loading from "../../../components/Loading";
import {EditOutlined} from "@ant-design/icons";

const Index = () => {
  const dispatch = useAppDispatch();
  const listingStatus = useSelector(state => state.status['getUsers'])
  const users = useSelector(state => state.users.users);

  useEffect(() => {
  }, [dispatch]);

  if (listingStatus !== "succeeded") return <Loading/>;

  return <div>
    <Table dataSource={Object.values(users)} pagination={false} rowKey="id">
      <Table.Column title="#" dataIndex="id" />
      <Table.Column title={"Identifier"} dataIndex="identifier" />
      <Table.Column title={"Platforms"} render={user => user.platforms.map((p: string) => <Tag color="orange">{p}</Tag>)} />
      <Table.Column dataIndex="id" align="center" render={id => <Link to={`/admin/users/${id}/edit`}><EditOutlined /></Link>}/>
    </Table>
    <Row style={{padding: 10}}>
      <Col>
        <Link to="/admin/users/add"><Button type="ghost">Add user</Button></Link>
      </Col>
      <Col></Col>
    </Row>
  </div>
};

export default Index;
